import {Card} from "baseui/card";
import {Block} from "baseui/block";
import {styled, useStyletron} from "baseui";
import {
    HeadingMedium,
    HeadingSmall,
    HeadingXSmall,
    LabelLarge,
    LabelMedium,
    LabelSmall,
    ParagraphMedium, ParagraphSmall
} from "baseui/typography";
import {FormControl} from "baseui/form-control";
import {Radio, RadioGroup} from "baseui/radio";
import {createContext, forwardRef, useContext, useEffect, useMemo, useRef, useState} from "react";
import {Input} from "baseui/input";
import {Button, SHAPE} from "baseui/button";
import {PhoneInput} from "baseui/phone-input";
import {Tabs, Tab} from "baseui/tabs-motion";
import {DIVIDER} from "baseui/table-semantic";
import {StyledDivider} from "baseui/divider";
import {httpInstance} from "./requests";
import {Table} from "baseui/table-semantic";
import {FlexGrid, FlexGridItem} from "baseui/flex-grid";
import {Link} from "baseui/link/styled-components";
import {Alert, Check, Delete, DeleteAlt} from "baseui/icon";
import {Checkmark} from "baseui/checkbox/styled-components";
import {StatefulTooltip, Tooltip} from "baseui/tooltip";
import {StatefulPanel} from "baseui/accordion";
import {Tag, TagKind} from "baseui/tag";
import {ARTWORK_TYPE, Banner, KIND, HIERARCHY} from "baseui/banner";
import Map from "./map/map.jsx";
import {SIZE, Spinner} from "baseui/spinner";
import InspectionsTable from "./InspectionsTable";
import {AuthContext} from "./Auth";
import posthog from "posthog-js";
import {toaster} from "baseui/toast";
import {useAuth0} from "@auth0/auth0-react";
import {Incorporation} from "./Incorporation";

const CarrierContext = createContext()


const Centered = styled('div', ({$theme}) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: $theme.sizing.scale1400,
    flexDirection: 'column',
}));

export const MainContent = () => {
    const [, theme] = useStyletron()
    const [carrier, setCarrier] = useState(null);
    const carrierDetailsRef = useRef(null);
    const {incrementSearches} = useContext(AuthContext);

    useEffect(() => {
        if (carrierDetailsRef.current == null) {
            return;
        }

        carrierDetailsRef.current.scrollIntoView({behavior: "smooth"})
    }, [carrierDetailsRef.current])


    const setCarrierCallback = (carrier) => {
        setCarrier(carrier)
        console.log(carrierDetailsRef.current);
        carrierDetailsRef.current?.scrollIntoView();
        incrementSearches()
    }


    return (
      <Centered>
          <CarrierContext.Provider value={{carrier, setCarrier: setCarrierCallback}}>
        <Block $style={{
            width: '100%',
            height: '100%',
            maxWidth: '80vw',
            boxShadow: theme.lighting.shadow700,
            padding: theme.sizing.scale1400,
            marginBottom: theme.sizing.scale1400,
            [theme.mediaQuery.small]: {
                maxWidth: '60vw',
            }
        }}>
            <Block $style={{
                display: 'flex',
            }}
                   flexDirection = {['column', 'row']}
            >
            <Block $style={{
                flex: '1 1 0px',
                marginRight: theme.sizing.scale1400,
            }}>
                <HeadingMedium>
                    Get 360 coverage on your carriers in a click
                </HeadingMedium>
                <ParagraphMedium>
                    We pull data from multiple sources to give you a full picture on the carrier so you can make an informed decision
                </ParagraphMedium>
            </Block>

            <Block $style={{
                flex: '1 1 0px',
            }}>
             <SearchForm />
            </Block>
            </Block>
        </Block>
              {/*<div ref={carrierDetailsRef}>*/}
          <CarrierDetails  ref={carrierDetailsRef} />
              {/*</div>*/}
          </CarrierContext.Provider>
      </Centered>
    )
}

const CarrierDetails = forwardRef((props, ref) => {
    const [, theme] = useStyletron()
    const {carrier} = useContext(CarrierContext);
    const [activeKey, setActiveKey] = useState("0");

    if (!carrier) {
        return null;
    }


    return <>
        <Block  ref={ref} $style={{
            width: '100%',
            height: '100%',
            maxWidth: '60vw',
            boxShadow: theme.lighting.shadow700,
            padding: theme.sizing.scale1400,
            marginBottom: theme.sizing.scale1400,
        }}>
        <LabelLarge $style={{
            marginBottom: theme.sizing.scale800,
        }}>
            Carrier details
        </LabelLarge>
        <Tabs
        onChange={({ activeKey }) => {
            setActiveKey(activeKey);
        }}
        activeKey={activeKey}
        // overrides={{
        //     TabBorder: {
        //         style: {
        //             marginBottom: theme.sizing.scale1400,
        //         }
        // }}}
    >
        <Tab title="Overview" >
            <Block $style={{
                height: '400px',
                paddingTop: theme.sizing.scale200,
            }}
            >
            <Overview setActiveTab={setActiveKey} carrier={carrier}/>
            </Block>
        </Tab>
        <Tab title="Inspections">
            <Block $style={{
                height: '800px',
                paddingTop: theme.sizing.scale900,
            }}>
               <Inspection />
            </Block>
            </Tab>
        <Tab title="Connected carriers">
            <Block $style={{
                height: '400px',
            }}>
            <ConnectedCarriers />
            </Block>
            </Tab>
            <Tab title="Incorporation data" >
                <Block $style={{
                height: '400px',
            }}>
                <Incorporation dotNumber={carrier.dot_number}/>
                </Block>
            </Tab>
    </Tabs>
    </Block>
    </>
});

const SearchForm = () => {
    const [value, setValue] = useState('1');
    const [identifierNumber, setIdentifierNumber] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const {setCarrier} = useContext(CarrierContext);
    const {authToken} = useContext(AuthContext);
    const {loginWithRedirect} = useAuth0();
    const getCarrier = async () => {
        setIsLoading(true)
        setCarrier(null);
        posthog.capture('carrier_searched', {
            identifierNumber,
            type: value == 1 ? 'DOT': 'MC',
        })

        httpInstance(authToken).post('/carrier_info', {
            ...( value == 1 ? {
                dot_number: identifierNumber
            } : {
                mc_number: identifierNumber
            }),
        }).then((res) => setCarrier({
            ...res.data,
        })).catch((err) => {
            if (err?.response?.status === 403) {
                posthog.capture('ip_blocked_error_shown')
                toaster.negative(<>
                    <Block>
                        <LabelMedium>
                            Sorry we we only allow IPs from north america and no VPNs. If you are based in the US and seeing this message, please contact us at support@carrierradar.com.
                            We also exempt users that have accounts with us. Please login
                            </LabelMedium>
                        <Button onClick={loginWithRedirect}>
                            Signup
                        </Button>
                        </Block>
                </>)
                return;
            }
            toaster.negative(<>
            Sorry we couldn't find that carrier. If it's a valid MC or DOT number, the carrier might not be indexed in our system. Our systems update daily and we will try to index it as soon as possible.
            </>)
        }).finally(() => setIsLoading(false))
    }

    return (
        <Block>
            <FormControl  label={"Search type"}>
                <RadioGroup
                    required
                    align="horizontal"
                    name="horizontal"
                    onChange={e => setValue(e.target.value)}
                    value={value}
                >
                    <Radio value="1">DOT number</Radio>
                    <Radio value="2">MC number</Radio>
                </RadioGroup>
            </FormControl>
            <FormControl  label={() => value == 1 ? "DOT #": "MC #"}>
               <Input required onChange={(e) => {
                   e = e;
                   e.preventDefault();
                   setIdentifierNumber(e.target.value);
               }} value={identifierNumber} />
            </FormControl>
            {/*<FormControl label={"Carrier phone number"} caption={"The number carrier called you from. We can use this to match with the number on their FMSCA profile"}>*/}
            {/*    <PhoneInput />*/}
            {/*</FormControl>*/}

            <Button isLoading={isLoading} onClick={() => {
                getCarrier()
            }} overrides={{
                BaseButton: {
                    style: {
                        width: '100%',
                    }
            }}}>
                Search
            </Button>
        </Block>
    )
}

const ConnectedCarriers = (props) => {
    const [, theme] = useStyletron();
    const {carrier} = useContext(CarrierContext);
    const dotNumber = carrier.dot_number;
    const [connectedCarriers, setConnectedCarriers] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const {authToken} = useContext(AuthContext);

    useEffect(() => {
        setIsLoading(true);
        httpInstance(authToken).post('/connected_brokers', {
            dot_number: dotNumber,
        }).then((res) => {
            setConnectedCarriers(res.data)
        }).finally(() => setIsLoading(false))
    }, [])

    const COLUMNS = ["DOT number", "Connection type", "Number of DNU reports"]

    const connected = useMemo(() => {
        return connectedCarriers.map((conn) => {
            return [conn.carrier.dot_number, conn.type, conn.carrier.db_reports.length]
        }).sort((connA, connB) => connB[2] - connA[2])
    })


    return <Block $style={{
        marginTop: theme.sizing.scale800,
        height: '400px',
    }}>
    <Table overrides={{
        Root: {
             style: {
                  maxHeight: '400px',
             }
        }
    }} isLoading={isLoading} columns={COLUMNS} data={connected} emptyMessage="No connections found"/>
    </Block>;
}

const itemProps = {
    // backgroundColor: 'mono300',
    // height: 'scale1000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

export const FieldWithInfo = (props) => {
    const [, theme] = useStyletron()
    const {label, info, smallText, icon, tooltipText} = props;
    console.log(tooltipText)
    return (
        <Block>
            <Block display="flex" alignItems="center">
        <LabelLarge marginRight={icon != null ? theme.sizing.scale200: 0}>
            {label}
        </LabelLarge>
                {tooltipText ? <StatefulTooltip content={tooltipText}>{icon}</StatefulTooltip>: icon}
            </Block>
        <ParagraphMedium>
            {info}
        </ParagraphMedium>
            {smallText}
        </Block>
    )
}

const Overview = (props) => {
    const [, theme] = useStyletron();
    const {setActiveTab} = props;
    const {carrier} = useContext(CarrierContext);
    if (carrier == null) {
        return null;
    }
    return (
        <Block>
            {/*<OverallResult />*/}
            <FlexGrid
                flexGridColumnCount={2}
                flexGridColumnGap="scale1200"
                flexGridRowGap="scale800"
            >
                <FlexGridItem >
                    <Block>
                        <Block display="flex" alignItems={"center"}>
                            <LabelLarge marginRight={theme.sizing.scale200}>
                                Address
                            </LabelLarge>
                            {carrier.address_info.is_virtual_address && <Tag kind={TagKind.red} closeable={false} overrides={{
                                Root: {
                                    style: {
                                        marginLeft: theme.sizing.scale300,
                                    }
                                }}}>
                                Virtual address
                            </Tag>
                            }
                        </Block>
                        <ParagraphMedium>
                            {carrier.address_info.address}
                        </ParagraphMedium>
                    </Block>
                </FlexGridItem>
                <FlexGridItem alignItems={"center"} justifyContent={"center"}>
                    <Block>
                        <Block display="flex" alignItems={"center"}>
                            <LabelLarge marginRight={theme.sizing.scale200}>
                                Phone number
                            </LabelLarge>
                            {carrier.phone_info.is_voip && <Tag kind={TagKind.red} closeable={false} overrides={{
                                Root: {
                                    style: {
                                        marginLeft: theme.sizing.scale300,
                                    }
                                }}}>
                                VOIP
                            </Tag>
                            }
                        </Block>
                        <Block>
                        <ParagraphMedium marginBottom={theme.sizing.scale300}>
                            {carrier.phone_info.number}
                        </ParagraphMedium>
                            <ParagraphSmall>
                                {carrier.phone_info.carrier_name}
                            </ParagraphSmall>
                        </Block>
                    </Block>
                </FlexGridItem>
                <FlexGridItem >
                    <FieldWithInfo label="MC" info={carrier.mc} />
                </FlexGridItem>
                <FlexGridItem >
                    <FieldWithInfo label="# DNU reports" info={carrier.db_reports?.length}/>
                </FlexGridItem>
            </FlexGrid>
        </Block>
    )
}

const getArtworkIcon = (kind) => {
    switch (kind) {
        case KIND.positive:
            return ({size}) => {
                return <Check size={size} />
            }
        case KIND.warning:
            return ({size}) => <Alert size={size} />
        case KIND.negative:
            return ({size}) => <Delete size={size} />
    }
}


const OverallResult = (props) => {
    const {carrierInfo} =  props
    let title = "";
    let style = "";
    const riskPercentage = carrierInfo?.risk?.risk_percentage;
    const riskMetadata = carrierInfo?.risk?.metadata;

    if (riskPercentage > 70) {
        title = "High rebrokering risk!"
        style = KIND.negative;
    } else if (riskPercentage > 30) {
        title = "Medium rebrokering risk"
        style = KIND.warning;
    } else {
        title = "Low rebrokering risk";
        style = KIND.positive;
    }

    return <Banner
        overrides={{
            Root: {
                style: {
                    marginLeft: 0,
                }
            }
        }}
        title={title}
        artwork={{
            icon: getArtworkIcon(style),
            type: ARTWORK_TYPE.icon,
        }}
        hierarchy={HIERARCHY.high} kind={style}
    >
        {riskMetadata && riskMetadata}
    </Banner>
}

const Inspection = (props) => {
    const {carrier: {dot_number: dotNumber}}  = useContext(CarrierContext);

    const [inspections, setInspections] = useState([]);
    const [isLoading, setIsLoading] = useState(null);
    const {authToken} = useContext(AuthContext);

    useEffect(() => {
        setIsLoading(true);
        httpInstance(authToken).post('/inspections', {
            dot_number: dotNumber,
        }).then((data) => setInspections(data.data)).finally(() => setIsLoading(false))
    }, [])
    if (isLoading) {
        return <Block display={"flex"} justifyContent={"center"}>
        <Spinner $size={SIZE.large} />
        </Block>
    }

    return (
        <Block>
        <Map inspections={inspections} />
            <InspectionsTable inspections={inspections} />
        </Block>
    )
}