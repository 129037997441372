import React, {Fragment} from 'react';
import {format} from 'date-fns';
import {Button} from 'baseui/button';
import {Heading, HeadingLevel} from 'baseui/heading';
import ChevronDown from 'baseui/icon/chevron-down';
import ChevronRight from 'baseui/icon/chevron-right';
import Overflow from 'baseui/icon/overflow';
import {StyledLink} from 'baseui/link';
import {StatefulMenu} from 'baseui/menu';
import {StatefulPopover, PLACEMENT} from 'baseui/popover';
import {withStyle, useStyletron} from 'baseui';
import {
    StyledTable,
    StyledHeadCell,
    StyledBodyCell,
} from 'baseui/table-grid';
import {Tag} from 'baseui/tag';
import {LabelMedium} from "baseui/typography";

const getVehicle = (inspection) => {
    return [

    ]
}
function buildRow(status, inspection) {
    return [
        inspection.id,
        new Date(inspection.inspection_date),
        // status,
        inspection.inspection_state,
        inspection.carrier_units.map((unit) => {
            return [
                unit.vin,
                unit.license,
                unit.make,
                unit.type_description,
            ]
        }),
        inspection.violations.map((violation) => {
            return [
                violation.violation_code,
                violation.description,
            ]
        })
    ];
}

function statusToTagKind(status) {
    switch (status) {
        case 'running': {
            return 'primary';
        }
        case 'passed': {
            return 'positive';
        }
        case 'failed': {
            return 'negative';
        }
        default: {
            return 'neutral';
        }
    }
}
function Tasks(props) {
    const [css] = useStyletron();
    return (
        <div
            className={css({
                gridColumn: 'span 3',
                padding: '32px 24px',
            })}
        >
            <StyledTable
                role="grid"
                $gridTemplateColumns="auto auto auto auto"
            >
                <div role="row" className={css({display: 'contents'})}>
                    <StyledHeadCell $sticky={false}>VIN</StyledHeadCell>
                    <StyledHeadCell $sticky={false}>License</StyledHeadCell>
                    <StyledHeadCell $sticky={false}>Make</StyledHeadCell>
                    <StyledHeadCell $sticky={false}>Type description</StyledHeadCell>
                </div>
                {props.tasks.map((task, index) => {
                    return (
                        <div role="row" className={css({display: 'contents'})}>
                            <StyledBodyCell>{task[0]}</StyledBodyCell>
                            <StyledBodyCell>
                                {task[1]}
                            </StyledBodyCell>
                            <StyledBodyCell>
                                {task[2] || 'N/A'}
                            </StyledBodyCell>
                            <StyledBodyCell>
                                {task[3] || 'N/A'}
                            </StyledBodyCell>
                        </div>
                    );
                })}
            </StyledTable>
        </div>
    );
}

function Violations(props) {
    const [css, theme] = useStyletron();
    return (
        <div
            className={css({
                gridColumn: 'span 3',
                padding: '32px 24px',
            })}
        >

            <StyledTable
                role="grid"
                $gridTemplateColumns="auto auto"
            >
                <div role="row" className={css({display: 'contents'})}>
                    <StyledHeadCell $sticky={false}>Violation code</StyledHeadCell>
                    <StyledHeadCell $sticky={false}>Description</StyledHeadCell>
                </div>
                {props.tasks.map((task, index) => {
                    return (
                        <div role="row" className={css({display: 'contents'})}>
                            <StyledBodyCell>{task[0]}</StyledBodyCell>
                            <StyledBodyCell>
                                {task[1]}
                            </StyledBodyCell>
                        </div>
                    );
                })}
            </StyledTable>
        </div>

    );
}
const CenteredBodyCell = withStyle(StyledBodyCell, {
    display: 'flex',
    alignItems: 'center',
});
function Row({striped, row, index}) {
    const [css] = useStyletron();
    const [expanded, setExpanded] = React.useState(index == 0);
    return (
        <div role="row" className={css({display: 'contents'})}>
            <CenteredBodyCell $striped={striped}>
                <Button
                    size="compact"
                    kind="tertiary"
                    onClick={() => setExpanded(!expanded)}
                    shape="square"
                >
                    {expanded ? (
                        <ChevronDown size={18} />
                    ) : (
                        <ChevronRight size={18} />
                    )}
                </Button>
                {row[0]}
            </CenteredBodyCell>
            {/*<CenteredBodyCell $striped={striped}>*/}
            {/*    <Tag*/}
            {/*        closeable={false}*/}
            {/*        variant="outlined"*/}
            {/*        kind={statusToTagKind(row[1])}*/}
            {/*    >*/}
            {/*        {row[1]}*/}
            {/*    </Tag>*/}
            {/*</CenteredBodyCell>*/}
            {/*<CenteredBodyCell $striped={striped}>*/}
            {/*    <StyledLink href={row[4]}>{row[3]}</StyledLink>*/}
            {/*</CenteredBodyCell>*/}
            <CenteredBodyCell $striped={striped}>
                {row[1].toLocaleString()}
            </CenteredBodyCell>
            <CenteredBodyCell $striped={striped}>
                {row[2]}
            </CenteredBodyCell>
            {expanded && <Tasks tasks={row[3]} />}
            {expanded && row[4].length > 0 && <Violations tasks={row[4]} />}
        </div>
    );
}
export default function InspectionsTable(props) {
    const {inspections} = props;
    const [css] = useStyletron();
    console.log(inspections);
    const data = inspections.map((inspection) => {
        return buildRow(null, inspection)
    })

    return (
        <div className={css({maxHeight: '400px'})}>
            <StyledTable
                $style={{
                    maxHeight: '400px',
                }}
                role="grid"
                $gridTemplateColumns="auto auto auto"
            >
                <div role="row" className={css({display: 'contents'})}>
                    <StyledHeadCell>ID</StyledHeadCell>
                    <StyledHeadCell>Date</StyledHeadCell>
                    <StyledHeadCell>State</StyledHeadCell>
                </div>
                {data.map((row, index) => {
                    const striped = true;
                    return <Row index={index} key={index} row={row} striped={striped} />;
                })}
            </StyledTable>
        </div>
    );
}