import * as React from 'react';
import {Button} from 'baseui/button';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalButton,
} from 'baseui/modal';
import {createContext, useContext, useEffect, useState} from "react";
import {FormControl} from "baseui/form-control";
import {Input} from "baseui/input";
import posthog from "posthog-js";
import {useAuth0} from "@auth0/auth0-react";

export const AuthContext = createContext();

export default function AuthWrapper(props) {
    const [isOpen, setIsOpen] = React.useState(false);
    const [searchesPerformed, setSearchesPerformed] = React.useState(0);
    const [email, setEmail] = useState('')
    const { loginWithRedirect, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [authToken, setAuthToken] = useState(null);
    useEffect(() => {
        const getToken = async () => {
            setAuthToken(await getAccessTokenSilently());
        }

        if (isAuthenticated) {
            getToken()
        }
    }, [isAuthenticated])

    useEffect(() => {
        setEmail(localStorage.getItem('email'))
    }, [setEmail])

    function close() {
        posthog.capture('user_signed_up', { property: email })
        localStorage.setItem("email", email)
        setIsOpen(false);
    }

    const incrementSearches = () => {
        setSearchesPerformed((state) => state + 1)
    }

    useEffect(() => {
        if (searchesPerformed > 2 && !isAuthenticated) {
            setIsOpen(true);
        }
    }, [searchesPerformed])
    return (
        <>
            <Modal onClose={close} isOpen={isOpen} closeable={false}>
                <ModalHeader>Please sign in</ModalHeader>
                <ModalBody>
                    To combat spam and to ensure that we can keep CarrierRadar free for everyone, please create an account. We will not sell your email. We only use it to ensure you are a human.
                </ModalBody>
                <ModalFooter>
                    <ModalButton onClick={() => {
                        loginWithRedirect();
                    }}>Sign in/up for free</ModalButton>
                </ModalFooter>
            </Modal>
            <AuthContext.Provider value={{incrementSearches, authToken}} >
                {props.children}
            </AuthContext.Provider>
        </>
    );
}