import mapboxgl from 'mapbox-gl';


import * as React from 'react';
import {useMemo, useRef} from 'react';
import {Map, Source, Layer} from 'react-map-gl';

import {clusterLayer, clusterCountLayer, unclusteredPointLayer} from './layers.js';
import {getStateLatLong} from "./states";
import {useStyletron} from "baseui";


// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// prettier-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const MAPBOX_TOKEN = 'pk.eyJ1IjoidGFudWp0aXdhcmk5OCIsImEiOiJjbGRxdzMyeWIweGtyM3ZycHphNDc1OXc0In0.LAoqA9FOvoTDFNISkIveTA'; // Set your mapbox token here

const convertInspectionToGeoJson = (inspection) => {
    console.log(inspection.inspection_state)
    return { "type": "Feature", "properties": { "id": inspection.id}, "geometry": { "type": "Point", "coordinates": getStateLatLong(inspection.inspection_state) } }
}

export default function InspectionMap(props) {
    const {inspections} = props;
    const [, theme] = useStyletron();
    const inspectionGeoJson = useMemo(() => {
        return {
            "type": "FeatureCollection",
            "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
            "features": inspections.map((insp) => convertInspectionToGeoJson(insp))
        }
    }, [inspections])

    const mapRef = useRef(null);

    const onClick = event => {
        const feature = event.features[0];
        const clusterId = feature.properties.cluster_id;

        const mapboxSource = mapRef.current.getSource('earthquakes');

        mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
            if (err) {
                return;
            }

            mapRef.current.easeTo({
                center: feature.geometry.coordinates,
                zoom,
                duration: 500
            });
        });
    };

    return (
        <>
            <Map
                style={{
                    height: '400px',
                    marginBottom: theme.sizing.scale800,
                }}
                initialViewState={{
                    latitude: 40.67,
                    longitude: -103.59,
                    zoom: 3
                }}
                mapStyle="mapbox://styles/mapbox/dark-v9"
                mapboxAccessToken={MAPBOX_TOKEN}
                interactiveLayerIds={[clusterLayer.id]}
                onClick={onClick}
                ref={mapRef}
            >
                <Source
                    id="earthquakes"
                    type="geojson"
                    data={inspectionGeoJson}
                    cluster={true}
                    clusterMaxZoom={14}
                    clusterRadius={50}
                >
                    <Layer {...clusterLayer} />
                    <Layer {...clusterCountLayer} />
                    <Layer {...unclusteredPointLayer} />
                </Source>
            </Map>
        </>
    );
}

