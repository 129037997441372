import {useContext, useEffect, useState} from "react";
import {httpInstance} from "./requests";
import {FlexGrid, FlexGridItem} from "baseui/flex-grid";
import {Block} from "baseui/block";
import {HeadingMedium, LabelLarge, ParagraphMedium} from "baseui/typography";
import {Tag, TagKind} from "baseui/tag";
import {FieldWithInfo} from "./Form";
import {Spinner} from "baseui/spinner";
import {useStyletron} from "baseui";
import { Table } from "baseui/table";
import {AuthContext} from "./Auth";

export const Incorporation = ({
    dotNumber,
}) => {
    const [incorporationDate, setIncorporationData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const {authToken} = useContext(AuthContext);
    useEffect(() => {
        setIsLoading(true);
        httpInstance(authToken).get(`/incorporation_data/${dotNumber}`).then((res) => {
            setIncorporationData(res.data)
        }).finally(() => setIsLoading(false))
    }, [dotNumber])

    const [, theme] = useStyletron();
    if (isLoading) {
        return <Spinner />
    }

    return (
        <Block>
        <Block marginBottom={theme.sizing.scale1200}>
            <FlexGrid
                flexGridColumnCount={2}
                flexGridColumnGap="scale1200"
                flexGridRowGap="scale800"
            >
                <FlexGridItem >
                    <FieldWithInfo label="Agent name" info={incorporationDate.agent_name} />
                </FlexGridItem>
                <FlexGridItem >
                    <FieldWithInfo label="Created at" info={new Date(incorporationDate.created_at).toLocaleDateString()}/>
                </FlexGridItem>
            </FlexGrid>
            </Block>
            <Block>
                <LabelLarge marginBottom={theme.sizing.scale400}>
                    Agents
                </LabelLarge>
                <Table
                columns={["ID", "Name", "Address"]}
                data={incorporationDate.officers.map((of) => {
                    const officer = of.officer;
                    return [officer.id, officer.name, officer.address]
                })}
                />
            </Block>
        </Block>
    )
}