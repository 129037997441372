import * as React from 'react';
import {
  HeaderNavigation,
  ALIGN,
  StyledNavigationItem as NavigationItem,
  StyledNavigationList as NavigationList,
} from 'baseui/header-navigation';
import {StyledLink as Link} from 'baseui/link';
import {Button, KIND} from 'baseui/button';
import { useStyletron } from 'baseui';
import {LabelMedium} from 'baseui/typography'
import {StyledSvg, Upload} from "baseui/icon";
export function Navigation() {
    const [, theme] = useStyletron();
  return (
    <HeaderNavigation overrides={{
        Root: {
            style: {
                padding: theme.sizing.scale600,
            }
        }
    }}>
      <NavigationList $align={ALIGN.left}>
        <NavigationItem><LabelMedium>CarrierBuster</LabelMedium></NavigationItem>
      </NavigationList>
      <NavigationList $align={ALIGN.center} />
      <NavigationList $align={ALIGN.right}>
          <Button kind={KIND.secondary} style={{
              marginRight: theme.sizing.scale800,
          }} endEnhancer={() => <Upload size={24} />} onClick={() => window.open("https://chrome.google.com/webstore/detail/carrierradar/hpgakmonlfbklionhmohjknpgdpcfbep", "_blank")}>
          Install chrome extension
        </Button>
          <Button kind={KIND.secondary} endEnhancer={() => <StyledSvg src={'/discord.svg'} color={'black'}/>} onClick={() => window.open("https://discord.gg/xMBB47tnam", "_blank")}>
          Join the discord
        </Button>
        {/* <NavigationItem>
          <Link href="#basic-link2">Tab Link Two</Link>
        </NavigationItem> */}
      </NavigationList>
      {/* <NavigationList $align={ALIGN.right}>
        <NavigationItem>
          <Button>Get started</Button>
        </NavigationItem>
      </NavigationList>  */}
    </HeaderNavigation>
  );
}