const states = {
    'AK': 'Alaska',
    'AL': 'Alabama',
    'AR': 'Arkansas',
    'AZ': 'Arizona',
    'CA': 'California',
    'CO': 'Colorado',
    'CT': 'Connecticut',
    'DC': 'District of Columbia',
    'DE': 'Delaware',
    'FL': 'Florida',
    'GA': 'Georgia',
    'HI': 'Hawaii',
    'IA': 'Iowa',
    'ID': 'Idaho',
    'IL': 'Illinois',
    'IN': 'Indiana',
    'KS': 'Kansas',
    'KY': 'Kentucky',
    'LA': 'Louisiana',
    'MA': 'Massachusetts',
    'MD': 'Maryland',
    'ME': 'Maine',
    'MI': 'Michigan',
    'MN': 'Minnesota',
    'MO': 'Missouri',
    'MS': 'Mississippi',
    'MT': 'Montana',
    'NC': 'North Carolina',
    'ND': 'North Dakota',
    'NE': 'Nebraska',
    'NH': 'New Hampshire',
    'NJ': 'New Jersey',
    'NM': 'New Mexico',
    'NV': 'Nevada',
    'NY': 'New York',
    'OH': 'Ohio',
    'OK': 'Oklahoma',
    'OR': 'Oregon',
    'PA': 'Pennsylvania',
    'RI': 'Rhode Island',
    'SC': 'South Carolina',
    'SD': 'South Dakota',
    'TN': 'Tennessee',
    'TX': 'Texas',
    'UT': 'Utah',
    'VA': 'Virginia',
    'VT': 'Vermont',
    'WA': 'Washington',
    'WI': 'Wisconsin',
    'WV': 'West Virginia',
    'WY': 'Wyoming'
}

const stateLatLong =  {
    Alaska: { state: 'Alaska', latitude: 61.385, longitude: -152.2683 },
    Alabama: { state: 'Alabama', latitude: 32.799, longitude: -86.8073 },
    Arkansas: { state: 'Arkansas', latitude: 34.9513, longitude: -92.3809 },
    Arizona: { state: 'Arizona', latitude: 33.7712, longitude: -111.3877 },
    California: { state: 'California', latitude: 36.17, longitude: -119.7462 },
    Colorado: { state: 'Colorado', latitude: 39.0646, longitude: -105.3272 },
    Connecticut: { state: 'Connecticut', latitude: 41.5834, longitude: -72.7622 },
    Delaware: { state: 'Delaware', latitude: 39.3498, longitude: -75.5148 },
    Florida: { state: 'Florida', latitude: 27.8333, longitude: -81.717 },
    Georgia: { state: 'Georgia', latitude: 32.9866, longitude: -83.6487 },
    Hawaii: { state: 'Hawaii', latitude: 21.1098, longitude: -157.5311 },
    Iowa: { state: 'Iowa', latitude: 42.0046, longitude: -93.214 },
    Idaho: { state: 'Idaho', latitude: 44.2394, longitude: -114.5103 },
    Illinois: { state: 'Illinois', latitude: 40.3363, longitude: -89.0022 },
    Indiana: { state: 'Indiana', latitude: 39.8647, longitude: -86.2604 },
    Kansas: { state: 'Kansas', latitude: 38.5111, longitude: -96.8005 },
    Kentucky: { state: 'Kentucky', latitude: 37.669, longitude: -84.6514 },
    Louisiana: { state: 'Louisiana', latitude: 31.1801, longitude: -91.8749 },
    Massachusetts: { state: 'Massachusetts', latitude: 42.2373, longitude: -71.5314 },
    Maryland: { state: 'Maryland', latitude: 39.0724, longitude: -76.7902 },
    Maine: { state: 'Maine', latitude: 44.6074, longitude: -69.3977 },
    Michigan: { state: 'Michigan', latitude: 43.3504, longitude: -84.5603 },
    Minnesota: { state: 'Minnesota', latitude: 45.7326, longitude: -93.9196 },
    Missouri: { state: 'Missouri', latitude: 38.4623, longitude: -92.302 },
    Mississippi: { state: 'Mississippi', latitude: 32.7673, longitude: -89.6812 },
    Montana: { state: 'Montana', latitude: 46.9048, longitude: -110.3261 },
    'North Carolina': { state: 'North Carolina', latitude: 35.6411, longitude: -79.8431 },
    'North Dakota': { state: 'North Dakota', latitude: 47.5362, longitude: -99.793 },
    Nebraska: { state: 'Nebraska', latitude: 41.1289, longitude: -98.2883 },
    'New Hampshire': { state: 'New Hampshire', latitude: 43.4108, longitude: -71.5653 },
    'New Jersey': { state: 'New Jersey', latitude: 40.314, longitude: -74.5089 },
    'New Mexico': { state: 'New Mexico', latitude: 34.8375, longitude: -106.2371 },
    Nevada: { state: 'Nevada', latitude: 38.4199, longitude: -117.1219 },
    'New York': { state: 'New York', latitude: 42.1497, longitude: -74.9384 },
    Ohio: { state: 'Ohio', latitude: 40.3736, longitude: -82.7755 },
    Oklahoma: { state: 'Oklahoma', latitude: 35.5376, longitude: -96.9247 },
    Oregon: { state: 'Oregon', latitude: 44.5672, longitude: -122.1269 },
    Pennsylvania: { state: 'Pennsylvania', latitude: 40.5773, longitude:-77.264 },
    'Rhode Island': { state: 'Rhode Island', latitude: 41.6772, longitude: -71.5101 },
    'South Carolina': { state: 'South Carolina', latitude: 33.8191, longitude: -80.9066 },
    'South Dakota': { state: 'South Dakota', latitude: 44.2853, longitude: -99.4632 },
    Tennessee: { state: 'Tennessee', latitude: 35.7449, longitude: -86.7489 },
    Texas: { state: 'Texas', latitude: 31.106, longitude: -97.6475 },
    Utah: { state: 'Utah', latitude: 40.1135, longitude: -111.8535 },
    Virginia: { state: 'Virginia', latitude: 37.768, longitude: -78.2057 },
    Vermont: { state: 'Vermont', latitude: 44.0407, longitude: -72.7093 },
    Washington: { state: 'Washington', latitude: 47.3917, longitude: -121.5708 },
    Wisconsin: { state: 'Wisconsin', latitude: 44.2563, longitude: -89.6385 },
    'West Virginia': { state: 'West Virginia', latitude: 38.468, longitude: -80.9696 },
    Wyoming: { state: 'Wyoming', latitude: 42.7475, longitude: -107.2085 },
    'District of Columbia': {
        latitude: 38.79164435,
        longitude: -77.11979522,
    }
}

export const getStateLatLong = (stateAbbrev) => {
    const full = states[stateAbbrev]
    const latLongObj = stateLatLong[full]
    return [latLongObj.longitude, latLongObj.latitude, 0]
}