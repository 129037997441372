import logo from './logo.svg';
import './App.css';
import { Client as Styletron } from 'styletron-engine-atomic';
import { LightTheme, BaseProvider, styled, DarkThemeMove, LightThemeMove } from 'baseui';
import { Provider as StyletronProvider } from 'styletron-react';
import {Navigation} from './Nav';
import {MainContent} from "./Form";
import {MyTheme} from "./theme";
import AuthWrapper from "./Auth";
import posthog from 'posthog-js'
import {ToasterContainer} from "baseui/toast";
import {Auth0Provider} from "@auth0/auth0-react";

const engine = new Styletron();



posthog.init('phc_tpHHeNq7jc9aQkntS2Zrx6EQqNBjfKBk4J6fpyJ1O2Z', { api_host: 'https://app.posthog.com' })


function App() {
  return (
      <Auth0Provider
    domain="dev-bp87ujzeog4y71dw.us.auth0.com"
    clientId="yutTGSzqdHgdASIRkSYo3MH0KONhYVIP"
    authorizationParams={{
      redirect_uri: window.location.href,
    }}
  >
    <StyletronProvider value={engine}>
      <BaseProvider theme={MyTheme}>
        <Navigation />
          <AuthWrapper>
              <ToasterContainer />
          <MainContent />
          </AuthWrapper>
      </BaseProvider>
    </StyletronProvider>
      </Auth0Provider>
  );
}

export default App;
